import { useState, React } from 'react'
import LoadingBar from 'react-top-loading-bar'
import Nav from '../../components/Navbar/Nav'
import "./Topics.css"
import Zoom from 'react-reveal/Zoom';


import Footer from '../../components/Footer/Footer'
import StartHero from '../../components/StartHero/StartHero';
const Topics = () => {
    const [progress, setProgress] = useState(100);
    const computerScience = ["Artificial Intelligence", "Computer Graphics", "Simulation and Modeling", "Digital Rights Management", "Signal & Image Processing", "Cloud Computing", "Cryptography", "Cluster Computing", "Autonomic & Trusted Computing", "Computing & cryptography", "Database & Data Mining", "Data Warehousing", "Data Clustering", "Big Data", "Service Mining in Marketing", "Convergence Technology for Data Mining & Al", "Web Data Mining", "Knowledge Discovery in database", "Service centric Software Engineering", "Service oriented requirements Engineering"]
    const informationTechnology = ["IT Convergence Technology", "Multimedia, Information-Convergence Security", "Web Based Learning: Innovation and Challenges", "Agricultural Informatics & Communication", "Community Information Systems", "Open Source: Challenges & Opportunities", "Computational Economics", "MANET", "VANET", "Wireless Sensor Networks", "Digital Photometry", "E-Governance", "E-Commerce", "Disaster Management", "Bio-Informatics", "Remote Sensing", "GIS & GPS", "Healthcare Informatics", "Information Ecology & Knowledge Management", "Neuroinformatics", "Web-Based learning", "Computational Economics"]
    const electronicsAndCommunications = ["Microelectronics", "Advanced Micro-controller and Microprocessors", "Electromagnetic Fields and Waves", "Antennas; Microwave Devices and C tubes", "Microwave Solid State Devices", "High power amplifiers", "Cognitive radios", "Wireless Communication", "Optical Communication", "Network Topology", "VLSI designs", "Numerical Methods and Computation", "Modulation Techniques", "TV and Radar", "MEMS"]
    const instrumentation = ["Medical and biological measurement", "Measurement techniques and measurements", "Data acquisition systems", "Sensors and Sensor Networks", "Nanotechnology", "Mechatronics", "Virtual instrumentation", "Image processing", "Video signal processing", "Speech and audio signal processing", "Linear and Non linear systems", "Robust control", "Optimal Control", "Process control", "Distributed control."]
    const roboticsAndAutomation = ["Computational Geometry", "Computer-aided Manufacturing", "Industrial Robotics", "Automation", "Biocybernetics", "Digital Electronics And Microprocessors", "Design And Control", "Robot Manipulators", "Bio-Inspired Robotics", "Biomedical Robotics", "Computational Intelligence in Robotics", "Field Robotics", "Haptics", "Human-Robot Interaction", "Humanoid Robotics", "Industrial Robotics"]
    return (
        <>
            <Nav />

            {/* <StartHero/> */}
            <section className="text-gray-600 body-font mt-4">
                <div className="container px-5 py-8 mx-auto bg-[#E3F0FB]">
                    <div className="w-full mx-auto text-center">




                        <div className="flex items-center mb-16 mr-8 justify-center" >

                            <h1 className='sm:text-4xl text-xl font-extrabold text-black sm:ml-12 bodyFont2'>Topics of interest include, but are not limited to, the following
                            </h1>
                        </div>

                        {/* ACCORDION */}
                         {/* <div class="accordion mx-auto" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Accordion Item #1
                                    </button>
                                </h2>
                                <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        Accordion Item #2
                                    </button>
                                </h2>
                                <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Accordion Item #3
                                    </button>
                                </h2>
                                <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="text-4xl my-6 text-black font-bold bodyFont2">Track 1 : Computer Science</div>
                        <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
                            {
                                computerScience.map((element) => (
                                    <div className="p-2 sm:w-1/2 w-full" key={element}>
                                        <div className="bg-[#57B7E7] rounded flex p-4 h-full items-center borderTopic">
                                            <i className="fa-solid fa-lightbulb mr-4 fa-xl iconclrps"></i>
                                            <span className="font-bold text-black sm:text-[1.3] text-sm">{element}</span>
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                        <div className="text-center">
                            <span className="inline-block h-1 w-24 rounded bg-[#016698] mt-8 mb-6"></span>
                        </div>

                        <div className="text-4xl my-6 text-black font-bold bodyFont2">Track 2 : Information Technology</div>
                        <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
                            {
                                informationTechnology.map((element) => (
                                    <div className="p-2 sm:w-1/2 w-full" key={element}>
                                        <div className="bg-[#57B7E7] rounded flex p-4 h-full items-center borderTopic">
                                            <i className="fa-solid fa-lightbulb mr-4 fa-xl iconclrps"></i>
                                            <span className="font-bold text-black sm:text-[1.3] text-sm">{element}</span>
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                        <div className="text-center">
                            <span className="inline-block h-1 w-24 rounded bg-[#016698] mt-8 mb-6"></span>
                        </div>
                        <div className="text-4xl my-6 text-black font-bold bodyFont2">Track 3 : Electronics and Communications</div>
                        <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
                            {
                                electronicsAndCommunications.map((element) => (
                                    <div className="p-2 sm:w-1/2 w-full" key={element}>
                                        <div className="bg-[#57B7E7] rounded flex p-4 h-full items-center borderTopic">
                                            <i className="fa-solid fa-lightbulb mr-4 fa-xl iconclrps"></i>
                                            <span className="font-bold text-black sm:text-[1.3] text-sm">{element}</span>
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                        <div className="text-center">
                            <span className="inline-block h-1 w-24 rounded bg-[#016698] mt-8 mb-6"></span>
                        </div>
                        <div className="text-4xl my-6 text-black font-bold bodyFont2">Track 4 : Instrumentation</div>
                        <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
                            {
                                instrumentation.map((element) => (
                                    <div className="p-2 sm:w-1/2 w-full" key={element}>
                                        <div className="bg-[#57B7E7] rounded flex p-4 h-full items-center borderTopic">
                                            <i className="fa-solid fa-lightbulb mr-4 fa-xl iconclrps"></i>
                                            <span className="font-bold text-black sm:text-[1.3] text-sm">{element}</span>
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                        <div className="text-center">
                            <span className="inline-block h-1 w-24 rounded bg-[#016698] mt-8 mb-6"></span>
                        </div>
                        <div className="text-4xl my-6 text-black font-bold bodyFont2">Track 5 : Robotics and Automation</div>
                        <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
                            {
                                roboticsAndAutomation.map((element) => (
                                    <div className="p-2 sm:w-1/2 w-full" key={element}>
                                        <div className="bg-[#57B7E7] rounded flex p-4 h-full items-center borderTopic">
                                            <i className="fa-solid fa-lightbulb mr-4 fa-xl iconclrps"></i>
                                            <span className="font-bold text-black sm:text-[1.3] text-sm">{element}</span>
                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                        <div className="text-center">
                            <span className="inline-block h-1 w-24 rounded bg-[#016698] mt-8 mb-6"></span>
                        </div>
                    </div>
                </div>
            </section>


            <Footer />
        </>
    )
}

export default Topics
